import {
    LockOpenIcon,
    GlobeEuropeAfricaIcon,
    LockClosedIcon,
} from "@heroicons/react/24/outline";
import Avatar, { AvatarInterface } from "../common/avatars/avatar";
import { FormattedMessage } from "react-intl";
import { unsplashCrop } from "../../utils";

type GroupType = {
    imageUrl: string;
    logoUrl: string;
    collaborator: {
        name: string;
        hasActiveLicense: boolean;
    };
    title: string;
    summary: string;
    members: AvatarInterface["user"][];
    isMember: boolean;
    slug: string;
    visibility: string;
};

export default function GroupCard({ group }: { group: GroupType }) {
    return (
        <li className="col-span-1 rounded-lg bg-white shadow overflow-hidden">
            <div className="relative flex flex-col items-center">
                <img
                    src={unsplashCrop(group.imageUrl)}
                    alt=""
                    className="w-full h-32 object-cover"
                />
                {group?.collaborator?.hasActiveLicense && (
                    <div className="-mt-12 bg-white rounded-lg size-24 border border-gray-300 flex flex-col items-center justify-center">
                        <img
                            src={group.logoUrl}
                            alt={`Logo: ${group.collaborator.name}`}
                            className="px-2"
                        />
                    </div>
                )}
            </div>
            <div className="flex flex-col items-center m-4">
                <span className="text-lg font-semibold whitespace-nowrap text-ellipsis overflow-hidden self-stretch text-center">
                    {group.title}
                </span>
                <span className="h-20 my-2 block text-base font-light text-center leading-6">
                    {group.summary.slice(0, 125) +
                        (group.summary.length > 125 ? "..." : "")}
                </span>
                <div className="mt-2 flex -space-x-2 overflow-hidden">
                    {group.members.map((member) => (
                        <Avatar key={member.id} user={member} />
                    ))}
                </div>
                <div className="w-full mt-2">
                    {group.isMember ? (
                        <a
                            href={route("show-group", group.slug)}
                            className="flex w-full justify-center rounded-lg border border-transparent bg-gray-900 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                        >
                            <FormattedMessage id="View group" />
                        </a>
                    ) : (
                        <a
                            href={route("show-group", group.slug)}
                            className="px-4 py-2.5 border border-transparent shadow-sm text-base font-medium rounded-md inline-flex justify-center items-center text-white bg-gradient-to-r from-voice-light to-voice hover:from-voice hover:to-voice-light focus:ring-voice w-full"
                        >
                            <FormattedMessage id="View group" />
                        </a>
                    )}
                </div>
                <div className="mt-4 flex items-center text-gray-500">
                    {group.isMember ? (
                        <LockOpenIcon className="size-5" />
                    ) : group.visibility === "public" ? (
                        <GlobeEuropeAfricaIcon className="size-5" />
                    ) : (
                        <LockClosedIcon className="size-5" />
                    )}
                    <span className="text-sm ml-1">
                        {group.isMember ? (
                            <FormattedMessage id="You are a member of the group" />
                        ) : (
                            <FormattedMessage
                                id="This is a :type group"
                                values={{ type: group.visibility }}
                            />
                        )}
                    </span>
                </div>
            </div>
        </li>
    );
}
